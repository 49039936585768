<template>
  <div>
    <file-pond
      ref="filepond"
      :label-idle="label"
      :accepted-file-types="types"
      :server="serverConfig"
      :max-file-size="maxSize"
      :image-edit-editor="doka"
      :image-edit-instant-edit="!disableEdit"
      :image-transform-image-filter="filterTransform"
      :max-files="maxFiles"
      :image-crop-aspect-ratio="imageCropAspectRatio"
      :allow-multiple="allowMultiple"
      :max-parallel-uploads="maxFiles"
      @updatefiles="updateHandler"
      @removefile="onRemove"
      @processfiles="onProcessFilesFinish"
    />
    <errors
      v-if="hasError"
      error-count="3"
      :error-messages="[...errorMessages, ...errorLimitFile]"
      class="mb-4"
    />
  </div>
</template>

<script>
  import vueFilePond from 'vue-filepond'
  import 'filepond/dist/filepond.min.css'
  import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css'
  import 'filepond-plugin-media-preview/dist/filepond-plugin-media-preview.min.css'
  import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
  import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
  import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size'
  import FilePondPluginImageEdit from 'filepond-plugin-image-edit'
  import FilePondPluginImageCrop from 'filepond-plugin-image-crop'
  import FilePondPluginImageResize from 'filepond-plugin-image-resize'
  import FilePondPluginImageTransform from 'filepond-plugin-image-transform'
  import FilePondPluginMediaPreview from 'filepond-plugin-media-preview'
  import axios from 'axios'
  import Errors from '@clickadilla/components/ui/Errors.vue'
  import { create } from '@/services/doka/doka.esm.min'
  import '@/services/doka/doka.min.css'
  import Media from '@/services/classes/Media.js'

  export default {
    name: 'FileUpload',
    components: {
      Errors,
      FilePond: vueFilePond(
        FilePondPluginFileValidateType,
        FilePondPluginFileValidateSize,
        FilePondPluginImagePreview,
        FilePondPluginImageEdit,
        FilePondPluginMediaPreview,
        FilePondPluginImageCrop,
        FilePondPluginImageResize,
        FilePondPluginImageTransform
      )
    },
    inject: ['settings'],
    props: {
      disableEdit: {
        type: Boolean,
        default: false
      },
      label: {
        type: String,
        default: 'Upload '
      },
      types: {
        type: String,
        default: 'image/jpeg, image/png, image/gif'
      },
      storeType: {
        type: String,
        default: 'file' // file | media
      },
      cropSize: {
        type: String,
        default: '' // '[width]x[height]'
      },
      value: {
        type: [String, Number, Array],
        default: () => []
      },
      dontFetchFile: Boolean,
      maxSize: {
        type: String,
        default: '1MB'
      },
      errorMessages: {
        type: Array,
        default: () => []
      },
      allowMultiple: {
        type: Boolean,
        default: false
      },
      maxFiles: {
        type: Number,
        default: 1
      },
      imageCropAspectRatio: {
        type: String,
        default: null
      }
    },
    data() {
      return {
        errorLimitFile: [],
        doka: create({}),
        files: []
      }
    },
    computed: {
      outputSize() {
        const [width, height] = this.cropSize ? this.cropSize.split('x') : [null, null]
        return { width, height }
      },
      cropAspectRatio() {
        return this.outputSize.height / this.outputSize.width
      },
      uploadUrl() {
        return {
          file: this.settings.fileUploadUrl,
          media: this.settings.mediaUploadUrl,
          documents: this.settings.documentUploadUrl
        }[this.storeType]
      },
      hasError() {
        return this.errorMessages?.length || this.errorLimitFile?.length
      },
      serverConfig() {
        return {
          process: async (fieldName, file, metadata, load, progress, error) => {
            const formData = new FormData()
            formData.append('file', file, file.name)

            const request = new XMLHttpRequest()
            request.open('POST', this.uploadUrl)
            request.setRequestHeader('Authorization', `Bearer ${this.$auth.getToken()}`)

            request.onload = () => {
              if (request.status >= 200 && request.status < 300) {
                const response = JSON.parse(request.response)
                this.fileUploaded(response.data)
                load(response.data.id)
              } else {
                const errors = JSON.parse(request.response)
                this.fileValidationErrors(errors)

                error(errors)
                progress()
              }
            }

            request.send(formData)
          },
          restore: async (uniqueFileId, load) => {
            const file = await axios.get(`${this.uploadUrl}/${uniqueFileId}`, {
              headers: { Authorization: `Bearer ${this.$auth.getToken()}` }
            })

            const filePath = file.data.data.src
            const blobFile = await axios.get(filePath, { responseType: 'blob' })
            load(blobFile.data)
          },
          load: async (uniqueFileId, load, error, progress, abort, headers) => {
            const file = await axios.get(`${this.uploadUrl}/${uniqueFileId}`, {
              headers: { Authorization: `Bearer ${this.$auth.getToken()}` }
            })

            const filePath = file.data.data.src
            const blobFile = await axios.head(filePath, { responseType: 'blob' })
            headers(
              Object.entries(blobFile.headers).reduce((acc, [key, value]) => `${acc}\n${key}: ${value}`, '')
            )
            load({ body: null })
          }
        }
      }
    },
    watch: {
      outputSize: {
        handler() {
          this.updateDoka()
        }
      },
      value: {
        handler() {
          this.addInitialFile()
        }
      }
    },
    mounted() {
      this.addInitialFile()
      this.initDokaLoad()
    },
    methods: {
      // https://stackoverflow.com/questions/58874156/make-filepond-image-transform-plugin-ignore-animated-gifs
      filterTransform: (file) => new Promise((resolve) => {
        // no gif mimetype, do transform
        if (!/image\/gif/.test(file.type)) {
          resolve(true)
          return
        }

        const reader = new FileReader()
        reader.onload = () => {
          const arr = new Uint8Array(reader.result)
          let i
          let len
          const { length } = arr
          let frames = 0

          // make sure it's a gif (GIF8)
          if (arr[0] !== 0x47 || arr[1] !== 0x49 || arr[2] !== 0x46 || arr[3] !== 0x38) {
            // it's not a gif, we can safely transform it
            resolve(true)
            return
          }

          for (i = 0, len = length - 9; i < len && frames < 2; i += 1) {
            if (
              arr[i] === 0x00
              && arr[i + 1] === 0x21
              && arr[i + 2] === 0xf9
              && arr[i + 3] === 0x04
              && arr[i + 8] === 0x00
              && (arr[i + 9] === 0x2c || arr[i + 9] === 0x21)
            ) {
              frames += 1
            }
          }

          // if frame count > 1, it's animated, don't transform
          if (frames > 1) {
            resolve(false)
            return
          }

          // do transform
          resolve(true)
        }
        reader.readAsArrayBuffer(file)
      }),
      initDokaLoad() {
        this.doka.on('load', () => {
          this.doka.setData({
            crop: {
              aspectRatio: this.cropAspectRatio
            },
            outputWidth: this.outputSize.width,
            outputHeight: this.outputSize.height
          })
        })
      },
      updateDoka() {
        this.doka.setOptions({
          cropAspectRatio: this.cropAspectRatio,
          cropShowSize: true
        })

        if (this.$refs.filepond) {
          this.doka.edit(this.$refs.filepond.getFile()?.file)
        }
      },
      addInitialFile() {
        if (!this.value || (Array.isArray(this.value) && !this.value.length)) {
          return
        }

        this.$refs.filepond.removeFiles()
        this.$refs.filepond.addFiles([
          {
            source: String(this.value),
            options: {
              type: this.dontFetchFile ? 'local' : 'limbo'
            }
          }
        ])
      },
      fileValidationErrors(error) {
        const { errors } = error
        this.errorLimitFile = errors.file
      },
      fileUploaded(serverFile) {
        this.$emit('input', new Media(serverFile))
      },
      updateHandler(e) {
        this.errorLimitFile = []
        this.$emit('update')

        if (!e.length) {
          this.$emit('input', null)
          this.$emit('verifications-input-handler', null)
        } else {
          this.$emit('verifications-input-handler', e)
        }
      },
      checkAllFilesIsUpload() {
        return this.$refs.filepond?.getFiles().every(({ serverId }) => serverId)
      },
      onRemove(error, file) {
        this.$emit('file-remove', file.serverId)
      },

      onProcessFilesFinish() {
        const ids = this.$refs.filepond?.getFiles().map(({ serverId }) => +serverId)
        this.$emit('process-finish', ids)
      }
    }
  }
</script>

<style lang="scss" scoped>
  ::v-deep {
    & .filepond--drop-label {
      height: 138px !important;
      border: 2px dashed var(--v-secondary-darken-base);
      border-radius: 8px;
    }
    & .filepond--panel-top {
      max-height: 100%;
    }
    & .filepond--image-preview-wrapper {
      min-height: 100% !important;
    }
  }
</style>
